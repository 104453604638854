export interface IContextState {
  connectedAccount: string | null;
}

export const SET_CONNECTED_ACCOUNT = 'SET_CONNECTED_ACCOUNT';

interface SetConnectedAccount {
  type: typeof SET_CONNECTED_ACCOUNT;
  payload: string;
}

export type ContextActionTypes = SetConnectedAccount;
