import { MerkleTree } from 'merkletreejs';
import { ethers } from 'ethers';
import whitelisted from '../whitelisted.json';

const makeMerkleLeafs = (): string[] => {
  return whitelisted.map(wallet => ethers.utils.keccak256(wallet));
};

export const makeMerkleTree = (): MerkleTree => {
  const merkleLeafs = makeMerkleLeafs();

  return new MerkleTree(merkleLeafs, ethers.utils.keccak256, {
    sortPairs: true,
  });
};
