import React, { useEffect } from 'react';
import { useContextDispatch } from 'context/context';
import { setConnectedAddress } from 'context/actions';
import { CheckConnectedWallet } from 'services/ConnectWallet';

const AppProvider: React.FunctionComponent = ({ children }) => {
  const dispatch = useContextDispatch();

  useEffect(() => {
    CheckConnectedWallet().then(account => {
      if (account) {
        dispatch(setConnectedAddress(account));
      }
    });
  }, []);
  return <>{children}</>;
};
export default AppProvider;
