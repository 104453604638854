import React from 'react';
import { ContextProvider } from 'context/context';
import AppProvider from 'components/providers/App';
import HomePage from 'pages/home-page';
import 'assets/sass/main.scss';

const App: React.FunctionComponent = () => {
  return (
    <ContextProvider>
      <AppProvider>
        <div className="celestial-app">
          <HomePage />
        </div>
      </AppProvider>
    </ContextProvider>
  );
};

export default App;
