import React, { ButtonHTMLAttributes } from 'react';
import clsx from 'clsx';
import './_styles.scss';

interface ICButtonProps extends ButtonHTMLAttributes<any> {
  className?: string;
}

const CButton: React.FunctionComponent<ICButtonProps> = ({ children, className, ...props }) => {
  return (
    <button type="button" className={clsx('celestial-button', className)} {...props}>
      <span>{children}</span>
    </button>
  );
};
export default CButton;
