import React from 'react';
import MintContainer from './components/mint-container';
import './_styles.scss';

const HomePage: React.FunctionComponent = () => {
  return (
    <div className="home-page-container">
      <MintContainer />
    </div>
  );
};
export default HomePage;
