export const CheckConnectedWallet = async (): Promise<string | null> => {
  // @ts-ignore
  const { ethereum } = window;
  if (!ethereum) {
    return null;
  }
  const accounts = await ethereum.request({ method: 'eth_accounts' });
  if (accounts.length) {
    return accounts[0];
  }
  return null;
};

export const ConnectWallet = async (): Promise<string | null> => {
  // @ts-ignore
  const { ethereum } = window;
  if (!ethereum) {
    return null;
  }
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  if (accounts.length) {
    return accounts[0];
  }
  return null;
};
