import React from 'react';
import './_styles.scss';

type IRowData = {
  label: string;
  value: string;
  className?: string;
};

const RowData: React.FC<IRowData> = ({ label, className = '', value }) => {
  return (
    <div className={`${className} row-data`}>
      <span className="label">
        {label}
        {': '}
      </span>
      <span className="value">{value}</span>
    </div>
  );
};

export default RowData;
