import React, { useEffect, useState } from 'react';
import { useContextDispatch, useContextState } from 'context/context';
import { setConnectedAddress } from 'context/actions';
import { ConnectWallet } from 'services/ConnectWallet';
import CButton from 'components/button';
import Counter from 'components/counter';
import { ethers } from 'ethers';
import CelestialsJson from '../../../../Celestials.json';
import BigNumber from 'bignumber.js';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import '../../_styles.scss';
import RowData from 'components/RowData';
import { makeMerkleTree } from 'services/MerkleTree';

const MintContainer: React.FunctionComponent = () => {
  const [updatedPrice, setUpatedPrice] = useState('0');
  const dispatch = useContextDispatch();
  const { connectedAccount } = useContextState();
  const CONTRACT_ADDRESS = '0x7Ea06Fb9Ea95fcb0858e213e25638f7CB1Cc4f37'; // TODO: mainet
  const [contract, setContract] = useState<ethers.Contract>();
  const [presaleActivation, setPresaleActivation] = useState(false);
  const [saleActivation, setSaleActivation] = useState(false);
  const [status, setStatus] = useState<'Whitelisted' | 'Not Whitelisted' | ''>('');
  const [totalSupply, setTotalSupply] = useState('0');
  const [price, setPrice] = useState(0);
  const [count, setCount] = useState(1);

  // @ts-ignore
  const { ethereum } = window;

  const alchemyProvider = new ethers.providers.AlchemyProvider(
    'rinkeby',
    'xbfBpuMsJqdiBmIdvkqKhdWhZrHRICGJ',
  );

  const preMint = async () => {
    if (!contract) return;

    const isPresaleActive = await contract.presaleIsActive();
    const isSaleActive = await contract.saleIsActive();

    if (isPresaleActive) {
      setPresaleActivation(isPresaleActive);
      const newPrice = new BigNumber((await contract.presalePrice()).toString())
        .div(new BigNumber(10).pow(18))
        .toNumber();

      setPrice(newPrice);

      setUpatedPrice(newPrice.toString());

      return;
    }

    if (isSaleActive) {
      setSaleActivation(isSaleActive);
      const newPrice = new BigNumber((await contract.salePrice()).toString())
        .div(new BigNumber(10).pow(18))
        .toNumber();

      setPrice(newPrice);

      setUpatedPrice(newPrice.toString());
    }
  };

  const getSigner = () => {
    const provider = new ethers.providers.Web3Provider(ethereum);
    const signer = provider.getSigner();
    return signer;
  };

  const getLeaf = async () => {
    const signer = getSigner();

    const leaf = ethers.utils.keccak256(await signer.getAddress());

    return leaf;
  };

  const getProof = async () => {
    const leaf = await getLeaf();

    const merkleTree = makeMerkleTree();

    const proof = merkleTree.getHexProof(leaf);
    return proof;
  };

  const readyToMint = async () => {
    if (!ethereum && !ethereum.selectedAddress) return;

    const signer = getSigner();

    setContract(contract?.connect(signer));

    const leaf = ethers.utils.keccak256(await signer.getAddress());

    const merkleTree = makeMerkleTree();

    const hexProof = merkleTree.getHexProof(leaf);

    setStatus(hexProof.length > 0 ? 'Whitelisted' : 'Not Whitelisted');
  };

  useEffect(() => {
    setInterval(async () => {
      if (!contract) return;

      const total = await contract.totalSupply();

      setTotalSupply(total.toString() || '0');
    }, 5000);
  }, [connectedAccount]);

  useEffect(() => {
    setContract(new ethers.Contract(CONTRACT_ADDRESS, CelestialsJson.abi, alchemyProvider));

    if (connectedAccount) readyToMint();

    preMint();
  }, [connectedAccount]);

  const onConnect = async () => {
    const account = await ConnectWallet();

    if (account) {
      dispatch(setConnectedAddress(account));
    }
  };

  const onMint = async () => {
    if (!contract) return;

    const proof = await getProof();
    const leaf = await getLeaf();

    try {
      if (presaleActivation) {
        const preSalePrice = (await contract.presalePrice()).mul(count);
        await (await contract.mintPresale(leaf, proof, count, { value: preSalePrice })).wait();
      }

      if (saleActivation) {
        const salePrice = (await contract.salePrice()).mul(count);
        await (await contract.mintSale(count, { value: salePrice })).wait();
      }
    } catch (err: any) {
      const errorMessage = err?.error?.message ?? err?.reason ?? err?.data?.message ?? err.message;

      toast(errorMessage, { type: 'error' });
    }
  };

  const onValueChange = (value: number) => {
    setCount(value);
    setUpatedPrice(new BigNumber(value).multipliedBy(price).toString());
  };

  return (
    <div className="mint-container">
      <ToastContainer />
      <img src="/Happy.png" className="header-image" width={100} height={100} />
      <span className="mint-title">MINTING SEQUENCE ENGAGED</span>
      <div className="form-container">
        <RowData
          className="margin-top"
          label="Wallet"
          value={connectedAccount || 'connect your wallet'}
        />
        {<RowData className="margin-top" label="Status" value={connectedAccount ? status : ''} />}
        <RowData className="margin-top" label="Supply Minted" value={`${totalSupply}/5555`} />
        <RowData className="margin-top" label="Price" value={updatedPrice.toString()} />
        <Counter
          className="margin-top"
          onValueChange={onValueChange}
          max={saleActivation ? 10 : 3}
          min={1}
        />
      </div>
      {connectedAccount ? (
        <CButton
          disabled={!saleActivation && !presaleActivation}
          style={{ marginTop: 8 }}
          onClick={onMint}
        >
          Mint
        </CButton>
      ) : (
        <CButton style={{ marginTop: 8 }} onClick={onConnect}>
          Connect Wallet
        </CButton>
      )}
    </div>
  );
};
export default MintContainer;
