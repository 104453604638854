import React, { useEffect, useState } from 'react';
import './_styles.scss';

interface ICounter {
  onValueChange: (value: number) => void;
  className?: string;
  max?: number;
  min?: 1;
}

const Counter: React.FC<ICounter> = ({ onValueChange, className = '', max = 10, min = 1 }) => {
  const [value, setValue] = useState<number>(min);

  useEffect(() => {
    onValueChange(value);
  }, [value]);

  return (
    <div className={`${className} counter-container`}>
      <button
        disabled={value === min}
        className="counter-btn"
        onClick={() => value > min && setValue(value - 1)}
      >
        -
      </button>
      <span>{value}</span>
      <button
        disabled={value === max}
        className="counter-btn"
        onClick={() => value < max && setValue(value + 1)}
      >
        +
      </button>
    </div>
  );
};

export default Counter;
