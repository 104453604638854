import * as Types from './types';

export const initialState: Types.IContextState = {
  connectedAccount: null,
};

export default function ContextReducer(
  state = initialState,
  action: Types.ContextActionTypes,
): Types.IContextState {
  switch (action.type) {
    case Types.SET_CONNECTED_ACCOUNT:
      return { ...state, connectedAccount: action.payload };
    default:
      return state;
  }
}
